.App {
  text-align: center;
  /* padding-left: calc(100vw - 100%); */
  background-color: #6cd9c7;
  height: 100vh;
}

.App {
  text-align: center;
}

.App-splash-logo {
  height: 40vmin;
}

.App-splash-header {
  /* background-color: #111111; */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@font-face {
  font-family: openSansBold;
  src: url(fonts/Open\ Sans\ Bold.ttf);
}

p {
  font-family: openSansBold;
  color: #092327;
}
